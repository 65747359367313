import React from 'react';

import Layout from '@components/layout';
import SEO from '@components/seo';
import CaseStudiesBanner from '@components/caseStudiesBanner';
import CaseStudiesList from '@components/caseStudiesList';
import TestimonialsSection from '@components/testimonialsSection';
import OurClients from '@components/ourClients';

import '@styles/styles.scss';

export const CaseStudies = ({ data }) => {
  const ctf = data.contentfulCaseStudiesPage;
  const ctf2 = data.contentfulLandingPage;
  const { title, subtitle, bannerImageTitle } = ctf;
  const assets = data.allContentfulAsset.edges.map(edge => edge.node);

  const caseStudies = data.allContentfulCaseStudy.edges.map(edge => edge.node)
    .sort((a, b) => b.order < a.order);

  const testimonialsHeadline = ctf2.testimonialsHeadline;
  const testimonials = ctf2.testimonials;

  const clientsTitle = ctf.clientsTitle;
  const clientsSubtitle = ctf.clientsSubtitle.clientsSubtitle;
  const clients = ctf.clients;

  return (
    <Layout>
      <SEO title="Case Studies" />
      <CaseStudiesBanner
        title={title}
        subtitle={subtitle.subtitle}
        bannerImageTitle={bannerImageTitle}
        assets={assets}
      />
      <CaseStudiesList
        caseStudies={caseStudies}
      />
      <section className="bg-light">
        <TestimonialsSection
          testimonialsHeadline={testimonialsHeadline}
          testimonials={testimonials}
          assets={assets}
        />
      </section>
      <OurClients
        clientsTitle={clientsTitle}
        clientsSubtitle={clientsSubtitle}
        clients={clients}
        assets={assets}
      />
    </Layout>
  );
};

export default CaseStudies;

export const pageQuery = graphql`
  query CaseStudiesQuery {
    contentfulCaseStudiesPage {
      title
      subtitle {
        subtitle
      }
      bannerImageTitle
      clientsTitle
      clientsSubtitle {
        clientsSubtitle
      }
      clients {
        clientName
        imageTitle
      }
    }
    contentfulLandingPage {
			testimonialsHeadline
			testimonialsSubtitle {
				testimonialsSubtitle
			}
			testimonials {
				testimonialTitle
        assetTitle
				testimonialContent
			}
    }
    allContentfulAsset {
      edges {
        node {
          title
          file {
            contentType
            url
            fileName
          }
          fixed {
            height
            width
            src
            srcSet
          }
          fluid(maxWidth: 980) {
            ...GatsbyContentfulFluid
          } 
        }
      }
    }
    allContentfulCaseStudy {
      edges {
        node {
          description {
            description
          }
          title
          image {
            fluid(maxWidth: 980) {
              ...GatsbyContentfulFluid
            } 
          }
          linkText
          link
          order
        }
      }
    }
  }
`;
