import React from 'react';
import Img from 'gatsby-image';

const ImgCol = ({ assets, caseStudy }) => (
  <div
    className="p-5"
  >
    <Img
      fluid={caseStudy.image.fluid}
      alt={caseStudy.image.title}
    />
  </div>
);

const TxtCol = ({ caseStudy }) => (
  <div className="h-100 w-100 flex-center p-5">
    <div className="w-75 d-flex flex-center flex-column">
      <h3 className="font-semibold">{caseStudy.title}</h3>
      <p className="font-medium text-dark">{caseStudy.description.description}</p>
      <a className="w-100 text-left" href={caseStudy.link}>{caseStudy.linkText}</a>
    </div>
  </div>
);

export const CaseStudiesList = ({
  caseStudies,
}) => {
  return caseStudies.map((caseStudy, i) => (
    <section key={i}>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            { i % 2 === 0 ?
              <TxtCol
                caseStudy={caseStudy}
              /> : 
              <ImgCol
                caseStudy={caseStudy}
              />
            }
          </div>
          <div className="col-md-6">
            { i % 2 !== 0 ?
              <TxtCol
                caseStudy={caseStudy}
              /> : 
              <ImgCol
                caseStudy={caseStudy}
              />
            }
          </div>
        </div>
      </div>
    </section>
  ));
};

export default CaseStudiesList;
