import React from 'react';
import Img from 'gatsby-image';

export const OurClients = ({ 
  clientsTitle,
  clientsSubtitle,
  clients,
  assets
}) => {
  return (
    <div id="ourclients" className="container py-5">
      <div className="row pt-5">
        <div className="col-12">
          <div className="text-center">
            <h2>{clientsTitle}</h2>
            <p className="text-muted">{clientsSubtitle}</p>
          </div>
        </div>
      </div>
      <div className="row pb-5">
        { clients.map((client, i) => (
          <div className="col-md-4 p-5" key={i}>
            <Img
              fluid={assets.filter(asset => asset.title === client.imageTitle)[0].fluid}
              alt={assets.filter(asset => asset.title === client.imageTitle)[0].title}
              width="100"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurClients;
