import React from 'react';

export const CaseStudiesBanner = ({
  title,
  subtitle,
  bannerImageTitle,
  assets
}) => {
  const banner = assets.filter(asset => asset.title === bannerImageTitle)[0];

  return (
    <section className="w-100" style={{ 
      backgroundImage: `url(${banner.file.url})`, 
      backgroundSize: 'cover' 
    }}>
      <div className="container py-5">
        <div className="row m-5">
          <div className="col-md-4">
            <div className="d-flex flex-column">
              <h1 className="h3 text-white font-weight-bold">{title}</h1>
              <h2 className="h6 text-white pt-3">{subtitle}</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CaseStudiesBanner;
